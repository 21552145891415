import React from 'react';
import Section from '../components/Section';
import ReturnToSiteLink from '../components/ReturnToSiteLink';
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SectionBanner from "../components/SectionBannerSmall"

function PrivacyPolicy(){

    return (
      <Layout>
        <Seo title="Privacy Policy" />
        <div>
          <SectionBanner
            bgImage="assets/images/img-whatwedo.jpg"
            title="Privacy Policy"
          />
          <Section id={"privacy"} indent={true} spacing={0} constrain>
            <ReturnToSiteLink
              location={`/`}
              position={"top"}
            ></ReturnToSiteLink>

            <div className="privacy-container">
              <div className="vertical-line"></div>
              <div className="policy">
                <div className="title">
                  <h2>Personal identification information</h2>
                </div>
                <div className="info">
                  <p>
                    We may collect personal identification information from
                    Users in a variety of ways, including, but not limited to,
                    when Users visit our site, fill out a form, and in
                    connection with other activities, services, features or
                    resources we make available on our Site. Users may be asked
                    for, as appropriate, name, email address, phone number.
                    Users may, however, visit our Site anonymously. We will
                    collect personal identification information from Users only
                    if they voluntarily submit such information to us. Users can
                    always refuse to supply personally identification
                    information, except that it may prevent them from engaging
                    in certain Site related activities.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Non-personal identification information</h2>
                </div>
                <div className="info">
                  <p>
                    We may collect non-personal identification information about
                    Users whenever they interact with our Site. Non-personal
                    identification information may include the browser name, the
                    type of computer and technical information about Users means
                    of connection to our Site, such as the operating system and
                    the Internet service providers utilized and other similar
                    information.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Web browser cookies</h2>
                </div>
                <div className="info">
                  <p>
                    Our Site may use "cookies" to enhance User experience.
                    User's web browser places cookies on their hard drive for
                    record-keeping purposes and sometimes to track information
                    about them. User may choose to set their web browser to
                    refuse cookies, or to alert you when cookies are being sent.
                    If they do so, note that some parts of the Site may not
                    function properly.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>How we use collected information</h2>
                </div>
                <div className="info">
                  <p>
                    Bixal collects and uses personal information for the
                    following purposes:
                  </p>
                  <br />
                  <div>
                    <b>To personalize user experience</b>
                  </div>
                  <div>
                    We may use information in the aggregate to understand how
                    our Users as a group use the services and resources provided
                    on our Site.
                  </div>
                  <br />
                  <div>
                    <b>To improve our Site</b>
                  </div>
                  <div>
                    We continually strive to improve our website offerings based
                    on the information and feedback we receive from you.
                  </div>
                  <br />
                  <div>
                    <b>To improve customer service</b>
                  </div>
                  <div>
                    Your information helps us to more effectively respond to
                    your customer service requests and support needs.
                  </div>
                  <br />
                  <div>
                    <b>To send periodic emails</b>
                  </div>
                  <div>
                    The email address Users provide will only be used to respond
                    to their inquiries, and/or other requests or questions.
                  </div>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>How we protect your information</h2>
                </div>
                <div className="info">
                  <p>
                    We adopt appropriate data collection, storage and processing
                    practices and security measures to protect against
                    unauthorized access, alteration, disclosure or destruction
                    of your personal information, username, password,
                    transaction information and data stored on our Site
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Sharing your personal information</h2>
                </div>
                <div className="info">
                  <p>
                    We do not sell, trade, or rent Users personal identification
                    information to others. We may share generic aggregated
                    demographic information not linked to any personal
                    identification information regarding visitors and users with
                    our business partners, trusted affiliates and advertisers
                    for the purposes outlined above.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Third party websites</h2>
                </div>
                <div className="info">
                  <p>
                    Users may find advertising or other content on our Site that
                    link to the sites and services of our partners, suppliers,
                    advertisers, sponsors, licensors and other third parties. We
                    do not control the content or links that appear on these
                    sites and are not responsible for the practices employed by
                    websites linked to or from our Site. In addition, these
                    sites or services, including their content and links, may be
                    constantly changing. These sites and services may have their
                    own privacy policies and customer service policies. Browsing
                    and interaction on any other website, including websites
                    which have a link to our Site, is subject to that website's
                    own terms and policies.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Changes to this privacy policy</h2>
                </div>
                <div className="info">
                  <p>
                    Bixal has the discretion to update this privacy policy at
                    any time. When we do, we will revise the updated date at the
                    bottom of this page. We encourage Users to frequently check
                    this page for any changes to stay informed about how we are
                    helping to protect the personal information we collect. You
                    acknowledge and agree that it is your responsibility to
                    review this privacy policy periodically and become aware of
                    modifications
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Your acceptance of these terms</h2>
                </div>
                <div className="info">
                  <p>
                    By using this Site, you signify your acceptance of this
                    policy. If you do not agree to this policy, please do not
                    use our Site. Your continued use of the Site following the
                    posting of changes to this policy will be deemed your
                    acceptance of those changes.
                  </p>
                </div>
              </div>

              <div className="policy">
                <div className="title">
                  <h2>Contacting us</h2>
                </div>
                <div className="info">
                  <p>
                    If you have any questions about this Privacy Policy, the
                    practices of this site, or your dealings with this site,
                    please contact us at:
                  </p>

                  <div>
                    <b>Bixal</b>
                  </div>
                  <address>
                    <div>3050 Chain Bridge Rd., Suite 305</div>
                    <div>Fairfax, VA 22030</div>
                    <div>
                      <a href="tel:1-703-634-5701">703.634.5701</a>
                    </div>
                  </address>

                  <div>
                    <a href="http://bixal.com">http://www.bixal.com</a>
                  </div>
                  <div>
                    <a href="mailto:info@bixal.com">info@bixal.com</a>
                  </div>
                  <br />
                  <p>This document was last updated on February 27, 2020.</p>
                  <div>
                    <span>
                      Copyright &copy; {new Date().getFullYear()} Bixal.{" "}
                    </span>
                  </div>
                  <div>
                    All Rights Reserved. Privacy Policy | Terms & Conditions
                  </div>
                </div>
              </div>
            </div>
            <ReturnToSiteLink
              location={`/`}
              position={"bottom"}
            ></ReturnToSiteLink>
          </Section>
        </div>
      </Layout>
    )
}

export default PrivacyPolicy;

import React from 'react';
import Icon from './Icon';
import {Link} from "gatsby"
import DecorativeLink from "../components/DecorativeLink"

function ReturnToSite({position, location, value}){
    const borderStyle = position.toLowerCase() === 'bottom' ? {borderTop: '2px solid #f2f2f2', padding: '20px 10px'} : {borderBottom: '2px solid #f2f2f2', padding: '20px 10px'};
    return (
      <div className="return-to-site-link" style={borderStyle}>
        <DecorativeLink>
          <Link
            to={location}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Icon type={"leftArrow"} color={"#000"}></Icon>
            <span> {value || "Back to site"}</span>
          </Link>
        </DecorativeLink>
      </div>
    )
}

export default ReturnToSite;

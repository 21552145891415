import React from "react"
import Section from "./Section"
import { useStaticQuery, graphql } from "gatsby"

export default function SectionBannerSmall(props) {
  const data = useStaticQuery(graphql`
    query {
      sectionHeroImages: allFile(
        filter: { sourceInstanceName: { eq: "images" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `);

  function handleBackgroundImport(d, path) {
    let srcPublic = "";

    d.sectionHeroImages.edges.forEach(image => {
      if (image.node.relativePath === path) {
        srcPublic = image.node.publicURL
      }
    });

    return srcPublic;
  }

  return (
    <Section
      className="section-banner--small"
      spacing={80}
      constrain
      indent={true}
      bgImage={handleBackgroundImport(data, props.bgImage)}
      bgColor={'transparent'}
    >
      <div className="section-banner--small-details">
        <h1
          className="section-banner--small-title"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
        {/* </h1> */}
      </div>
    </Section>
  )
}
